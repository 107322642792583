import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router-dom';

import type { AccessMatrixKey } from './types';
import { userStoreInstance } from './../Auth/store';

type ProtectedRouteProps = {
  element: JSX.Element;
  matrixKey: AccessMatrixKey;
};

const ProtectedRoute = observer(({ element, matrixKey }: ProtectedRouteProps) => {
  const doesHaveAccess = userStoreInstance.accessMatrix.doesHaveAccess(matrixKey);
  return doesHaveAccess ? element : <Navigate to="/" />;
});

export default ProtectedRoute;
