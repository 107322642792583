import { action, makeAutoObservable } from 'mobx';
import {
  // CategoriesType,
  fetchCategories,
  fetchNotifications,
  fetchNotificationsUnread,
  fetchSettings,
  fetchUnreadCount,
  // NotificationsType,
  postReadAll,
  postSettings,
} from '../api';
import { initialNotificationMenu } from '../consts';
import type { State } from '@/types';
import type { CategoriesType, NotificationSettings, NotificationsType } from '../types';

export const initialNotificationSettings: NotificationSettings[] = [{ key: 'all', name: 'Общие', value: true }];

export class NotificationStore {
  notifications: NotificationsType['data'] = [];
  notificationSettings = initialNotificationSettings;
  _notificationSettings: NotificationSettings[] = [];
  notificationMenu = initialNotificationMenu;
  searchText = '';
  notificationType = 0;
  categories: CategoriesType['data'] = { list: [], total: 0, unread: 0 };
  count = 0;
  countUnread = 0;
  paging = { offset: 0, limit: 20 };
  private _isOpenSettingsModal = false;

  private fetchState: State = 'pending';

  get isLoading() {
    return this.fetchState === 'pending';
  }

  get allCount() {
    return this.notifications.length;
  }

  get isOpenSettingsModal() {
    return this._isOpenSettingsModal;
  }

  set isOpenSettingsModal(value) {
    this._isOpenSettingsModal = value;
  }

  showSettingsModal = () => {
    this.isOpenSettingsModal = true;
  };

  hideSettingsModal = () => {
    this.isOpenSettingsModal = false;
  };

  constructor() {
    makeAutoObservable(this);
  }

  init() {
    this.loadNotifications(0);
    this.getCategories();
    this.getSettings();
  }

  //#region paging
  get page() {
    return Math.floor(this.paging.offset / this.paging.limit) + 1;
  }

  set page(page: number) {
    this.paging.offset = (page - 1) * this.paging.limit;
  }

  setPage = (page: number) => {
    this.page = page;
    this.loadNotifications();
  };

  get pageCount() {
    return this.notifications ? Math.ceil(this.count / this.paging.limit) : 0;
  }
  //#endregion

  loadNotifications = (value?: number) => {
    this.fetchState = 'pending';
    if ((value && value === 99) || this.notificationType === 99) {
      fetchNotificationsUnread({
        limit: this.paging.limit,
        offset: this.paging.offset,
      }).then(
        action('notificationsFetchSuccess', (notificationsResponse) => {
          this.notifications = notificationsResponse.data;
          this.count = notificationsResponse.total;
          this.paging = { ...this.paging, offset: notificationsResponse.offset };
          this.fetchState = 'done';
        }),
        action('notificationsFetchError', () => {
          this.fetchState = 'error';
        })
      );
    } else {
      fetchNotifications({
        limit: this.paging.limit,
        offset: this.paging.offset,
        category_id: value || this.notificationType,
      }).then(
        action('notificationsFetchSuccess', (notificationsResponse) => {
          this.notifications = notificationsResponse.data;
          this.count = notificationsResponse.total;
          this.paging = { ...this.paging, offset: notificationsResponse.offset };
          this.fetchState = 'done';
        }),
        action('notificationsFetchError', () => {
          this.fetchState = 'error';
        })
      );
    }
  };

  getCategories() {
    this.fetchState = 'pending';
    fetchCategories().then(
      action('notificationsCategoriesSuccess', (count) => {
        this.categories = count.data;
        this.fetchState = 'done';
      }),
      action('notificationsCategoriesError', () => {
        this.fetchState = 'error';
      })
    );
  }

  getUnreadCount() {
    this.fetchState = 'pending';
    fetchUnreadCount().then(
      action('notificationsCountSuccess', (count) => {
        this.countUnread = count.count;
        this.fetchState = 'done';
      }),
      action('notificationsCountError', () => {
        this.fetchState = 'error';
      })
    );
  }

  setSearchText = (text: string) => {
    this.searchText = text;
    this.notificationMenu = initialNotificationMenu.filter((item) =>
      item.name.toLowerCase().includes(this.searchText.toLowerCase())
    );
    if (this.notificationMenu.length === 0) this.notificationMenu = [initialNotificationMenu[0]];
  };

  getFiltered = (value: number) => {
    if (value === 0) return this.notifications;
    if (value === 99) return this.notifications.filter((notification) => !notification.is_read);
    return this.notifications.filter((notification) => notification.category_id.id === value);
  };

  setNotificationType = (value: number) => {
    this.notificationType = value;
  };

  getNotificationCount = (value: number) => {
    if (value === 0) return this.categories.total;
    if (value === 99) return this.categories.unread;
    return this.categories.list?.find((li) => li.category_id === value)?.count || 0;
    // return this.getFiltered(value).length;
  };

  readAll = () => {
    postReadAll().then(
      action('notificationsFetchSuccess', () => {
        // this.notifications = notificationsResponse.data;
        // this.state = 'done';
        this.getUnreadCount();
        this.getCategories();
        this.notifications.forEach((notification) => {
          if (
            notification.category_id.id === this.notificationType ||
            this.notificationType === 0 ||
            this.notificationType === 99
          )
            notification.is_read = true;
        });
      }),
      action('notificationsFetchError', () => {
        // this.state = 'error';
      })
    );
  };

  getSettings() {
    this.fetchState = 'pending';
    fetchSettings().then(
      action('notificationsCountSuccess', (data) => {
        this.notificationSettings = data.data;
        this._notificationSettings = data.data;
        this.fetchState = 'done';
      }),
      action('notificationsCountError', () => {
        this.fetchState = 'error';
      })
    );
  }

  updateSettings(index: number, value: boolean) {
    this._notificationSettings[index].value = value;
  }

  setSettings() {
    this.fetchState = 'pending';
    postSettings(this._notificationSettings).then(
      action('notificationsSettingsSuccess', () => {
        this.getSettings();
        this.hideSettingsModal();
        this.fetchState = 'done';
      }),
      action('notificationsSettingsError', () => {
        this.fetchState = 'error';
      })
    );
  }
}

const notificationStore = new NotificationStore();

export default notificationStore;
