import DocTitle from '@/components/DocTitle';
import { Container, Row, Col } from 'react-bootstrap';

function Page404() {
  return (
    <>
      <DocTitle title="404 – Страница не найдена – СКДФ" />
      <Container className="d-grid align-items-center text-center h-100">
        <Row>
          <Col md={{ span: 5, offset: 3 }}>
            <h1 className="text-center fw-semibold" style={{ fontSize: '9.25rem', lineHeight: '1', color: '#ccced2' }}>
              404
            </h1>
            <h2 className="text-center mb-2r py-1">Страница не найдена</h2>
            <p>
              Возможно, вы перешли по ссылке, в&nbsp;которой была допущена ошибка, или ресурс был удален. Попробуйте
              перейти на&nbsp;<a href="/map">карту</a> или&nbsp;в&nbsp;<a href="/roads">реестр автомобильных дорог</a>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Page404;
