import { ForwardedRef, forwardRef } from 'react';
import { CloseButtonProps, ToastContainer, ToastContainerProps, TypeOptions } from 'react-toastify';
import SkdfIcon from '@/components/SkdfIcon';

const CloseButton = ({ closeToast }: CloseButtonProps) => (
  <SkdfIcon name="cross" className="align-self-baseline text-placeholder" onClick={closeToast} />
);

// const options = {
//   position: 'top-right',
//   autoClose: 5000,
//   hideProgressBar: false,
//   closeOnClick: true,
//   pauseOnHover: true,
//   draggable: true,
//   progress: undefined,
// };
// const t = (
//   <ToastContainer
//     position="top-right"
//     autoClose={5000}
//     hideProgressBar={false}
//     newestOnTop={false}
//     closeOnClick
//     rtl={false}
//     pauseOnFocusLoss
//     draggable
//     pauseOnHover
//   />
// );

const Icon: Record<TypeOptions, Record<string, string>> = {
  info: {
    iconName: 'info',
    className: 'text-primary',
  },
  warning: {
    iconName: 'info',
    className: 'text-warning',
  },
  error: {
    iconName: 'info',
    className: 'text-danger',
  },
  success: {
    iconName: 'circle_verified',
    className: 'text-success',
  },
  default: {
    iconName: 'info',
    className: 'text-primary',
  },
};

function ToastifyContainer(props: ToastContainerProps, ref: ForwardedRef<HTMLDivElement>) {
  return (
    <ToastContainer
      ref={ref}
      theme="colored"
      closeButton={CloseButton}
      position="bottom-right"
      icon={({ type }) => {
        const { iconName, className } = Icon[type];
        return <SkdfIcon name={iconName} className={className} />;
      }}
      {...props}
    />
  );
}

export default forwardRef(ToastifyContainer);
