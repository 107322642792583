import { action, makeObservable, observable } from 'mobx';
import { Log, User, UserManager } from 'oidc-client-ts';

import { AccessMatrixStore } from '@/features/AccessMatrix/store';
import { userManagerSettings } from '@/userManagerSettings';
import PlanningAccess from '@/features/PlanningAccess/store';
import { Account, fetchProfile } from '../api';

class UserStore {
  #userManager: UserManager;
  accessMatrix = new AccessMatrixStore(this);
  planningAccess = new PlanningAccess();
  public user: User | 'anonymous' | null = null;
  public account: Account | null = null;

  constructor() {
    makeObservable(this, {
      user: observable,
      getUser: action,
      setUser: action,
      renewToken: action,
    });
    // User manager
    this.#userManager = new UserManager(userManagerSettings);
    Log.setLogger(console);
    Log.setLevel(Log.INFO);
  }

  get isSignedIn() {
    return this.user !== null && this.user !== 'anonymous' && this.user.profile.idp !== 'Esia';
  }

  login() {
    const { pathname, href, origin } = window.location;
    localStorage.setItem('afterSignInRedirect', pathname === '/' ? `${origin}/desktop` : href);
    return this.#userManager.signinRedirect();
  }

  logout() {
    localStorage.setItem('afterSignOutRedirect', window.location.href);
    return this.#userManager.signoutRedirect();
  }

  init = async () => {
    // await this.querySessionStatus();
    await this.getUser();
  };

  setUser = async (user: User | 'anonymous' | null) => {
    this.user = user;
    if (this.user !== null && this.user !== 'anonymous' && this.user.profile.idp !== 'Esia') {
      await this.accessMatrix.getAccessMatrix();
      return;
    }
    if (this.user !== null && this.user !== 'anonymous' && this.user.profile.idp === 'Esia') {
      this.accessMatrix.accessMatrix.menu_account_page = true;
    }
    this.accessMatrix.setDone();
  };

  getUser = async () => {
    const user = await this.#userManager.getUser();
    await this.setUser(user ? user : 'anonymous');

    if (user && user.profile.idp !== 'Esia') {
      await this.getAccountInfo();
    }
  };

  getAccountInfo = async () => {
    const account = await fetchProfile();
    if (account) {
      this.account = account;
    }
  };

  getPlanningAccess = async () => {
    await this.planningAccess.getUserAccess();
  };

  renewToken = async () => {
    return this.#userManager
      .signinSilent()
      .then((user) =>
        action(() => {
          this.setUser(user ? user : 'anonymous');
        })
      )
      .catch((reason) => Promise.reject(reason));
  };

  // querySessionStatus = async () => {
  //   const user = await this.#userManager.getUser();
  //   if (user !== null) {
  //     await this.#userManager
  //       .querySessionStatus()
  //       .then((status) => {
  //       })
  //       .catch(async (e) => {
  //         if (e.error === 'login_required') {
  //           await this.#userManager.removeUser();
  //           this.setUser('anonymous');
  //         }
  //       });
  //   }
  // };

  removeUser = async () => {
    await this.#userManager.removeUser();
  };
}

const userStoreInstance = new UserStore();

if (import.meta.env.DEV) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window['userStoreInstance'] = userStoreInstance;
}

export { UserStore, userStoreInstance };
