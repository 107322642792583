import { makeAutoObservable } from 'mobx';

import type { FeatureFlagsRecord } from './types';

import flagsDev from '../../../config/features.dev.json';
import flagsDevelopment from '../../../config/features.development.json';
import flagsRdn from '../../../config/features.rdn.json';
import flagsSft from '../../../config/features.sft.json';

const flagsRecord: Record<string, FeatureFlagsRecord> = {
  dev: flagsDev,
  development: flagsDevelopment,
  rdn: flagsRdn,
  sft: flagsSft,
};

type DefinedModes = keyof typeof flagsRecord;

export class FeatureFlagsStore {
  features: FeatureFlagsRecord;
  constructor() {
    const mode: DefinedModes = (import.meta.env.MODE as DefinedModes) ?? 'dev';
    this.features = flagsRecord[mode];

    console.log('[FeatureFlagsStore] constructor: flags loaded', this.features);

    makeAutoObservable(this);
  }
}

const featureFlagsStoreInstance = new FeatureFlagsStore();

export default featureFlagsStoreInstance;
