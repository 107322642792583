export type NotificationMenu = { id: number; name: string; icon: string };
export const initialNotificationMenu: NotificationMenu[] = [
  {
    id: 0,
    name: 'Все',
    icon: 'all',
  },
  {
    id: 99,
    name: 'Непрочитанные',
    icon: 'unread',
  },
  {
    id: 1,
    name: 'Общие',
    icon: 'info',
  },
  {
    id: 2,
    name: 'Автомобильные дороги',
    icon: 'road',
  },
  {
    id: 3,
    name: 'Искусственные сооружения',
    icon: 'bridge_outline',
  },
  {
    id: 4,
    name: 'Программы дорожных работ',
    icon: 'work_program',
  },
  {
    id: 5,
    name: 'Проекты',
    icon: 'project',
  },
  {
    id: 6,
    name: 'Мероприятия',
    icon: 'calendar',
  },
  {
    id: 7,
    name: 'Дорожно-транспортные происшествия',
    icon: 'car_outline',
  },
  {
    id: 8,
    name: 'Сообщения граждан',
    icon: 'e-mail',
  },
  {
    id: 9,
    name: 'Задачи',
    icon: 'task',
  },
  {
    id: 10,
    name: 'Документы',
    icon: 'document',
  },
  {
    id: 11,
    name: 'Протоколы',
    icon: 'protocool',
  },
  {
    id: 12,
    name: 'Контакты',
    icon: 'contact',
  },
  {
    id: 13,
    name: 'Планы закупок',
    icon: 'plan',
  },
  {
    id: 14,
    name: 'Планы-графики',
    icon: 'plan_chart',
  },
  {
    id: 15,
    name: 'Извещения о закупке',
    icon: 'notice',
  },
  {
    id: 16,
    name: 'Контракты / договоры',
    icon: 'contract',
  },
  {
    id: 17,
    name: 'Статистика',
    icon: 'chart',
  },
  {
    id: 18,
    name: 'Добро пожаловать',
    icon: 'flag',
  },
];
