import axios, { type AxiosInstance, type AxiosRequestConfig, type AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import { userStoreInstance } from './features/Auth/store';

export const baseURL = import.meta.env.VITE_SKDF_URL || 'http://localhost:3000/api/v1/portal';
export const baseSpecialistURL = import.meta.env.VITE_SPECIALIST_SKDF_URL || 'http://localhost:3000/api/v1/skdf';
const client = axios.create({ baseURL });

// const client = axios.create({ baseURL: 'https://xn--p1a.xn--d1aluo.xn--p1ai/api/v1/portal' });

// Request interceptor for API calls
client.interceptors.request.use(
  async (config) => {
    const { user } = userStoreInstance;
    const isSignedIn = user && user !== 'anonymous';
    const isESIAUser = isSignedIn && user.profile.idp === 'Esia';
    const isAccountRequest = config.url === 'account';
    const isESIAUserGetAccount = isESIAUser && isAccountRequest;

    if ((isSignedIn && !isESIAUser) || isESIAUserGetAccount) {
      config.headers = {
        Authorization: `Bearer ${user.access_token}`,
      };
      config.baseURL = baseSpecialistURL;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
client.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalRequest = error.config;
    if ([403, 401].includes(error.response.status)) {
      if (originalRequest._retry) {
        await userStoreInstance.removeUser();
        window.location.reload();
      } else {
        originalRequest._retry = true;
        await userStoreInstance.renewToken().catch(async (reason) => {
          await userStoreInstance.removeUser();
          window.location.reload();
        });
        const { user } = userStoreInstance;
        if (user && user !== 'anonymous') {
          axios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`;
          return client(originalRequest);
        }
      }
    }
    return Promise.reject(error);
  }
);

if (import.meta.env.DEV) {
  client.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      let message = String(error);
      if (axios.Cancel) {
        return;
      }
      if (axios.isAxiosError(error)) message = error.message;
      toast.error(message, { autoClose: false });
    }
  );
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window['httpClient'] = client;
} else if (import.meta.env.PROD) {
  client.interceptors.response.use(undefined, () => {
    toast.error('Произошла ошибка при загрузке данных', { autoClose: false });
  });
}

export class RequestManager {
  private client: AxiosInstance;
  private currentRequest: AbortController | null;

  constructor(client: AxiosInstance) {
    this.client = client;
    this.currentRequest = null;
  }

  public request = async <R>(config: AxiosRequestConfig): Promise<AxiosResponse<R>> => {
    if (!this.currentRequest?.signal.aborted) {
      this.currentRequest?.abort();
    }

    this.currentRequest = new AbortController();

    return await this.client.request<R>({ ...config, signal: this.currentRequest.signal });
  };
}

export const defaultRequestManager = new RequestManager(client);

export default client;
