import { makeAutoObservable, runInAction } from 'mobx';
import type { PlanningUser, UserRoles } from '@/planning/federal/api';
import { fetchPlanningUserAttributes, Levels } from '@/planning/federal/api';
import { ProgramTypeKeys } from '@/planning/federal/programs/ProgramsPage/types';
import { UserRole, fetchCurrentUserRoles } from '@/planning/regional/api';

enum AccessEntries {
  CreateEvent = 'create_event',
  CreateEventFromEntity = 'create_event_from_entity',
  EditEvent = 'edit_event',
  EditEventBlocks = 'edit_event_blocks',
  CreateEventVersion = 'create_event_version',
  CreateProgram = 'create_program',
  EditProgram = 'edit_program',
  CreateWork = 'create_work',
  EditWork = 'edit_work',
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const rolesPermissions: Record<UserRoles, AccessEntries[]> = {
  program_operator: [],
  events_operator: [],
  program_coordinator: [],
  aggregate_program_coordinator: [],
  extended_program_coordinator: [],
  signatory: [],
  superadmin: [],
  director: [],
};

class PlanningAccess {
  planningUserAttributes: PlanningUser | null = null;
  regionalRoles: UserRole[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  getUserAccess = async () => {
    const planningUserAttributes = await fetchPlanningUserAttributes();
    const regionalRoles = await fetchCurrentUserRoles();

    runInAction(() => {
      this.planningUserAttributes = planningUserAttributes;
      this.regionalRoles = regionalRoles.roles;
    });
  };

  userHasRegionalRole = (regionalRole: string) => {
    return this.regionalRoles.some((role) => role.key === regionalRole);
  };

  getRegionalRoleAccess = (role: UserRole['key']) => {
    const hasRole = this.userHasRegionalRole(role);
    const hasSuperAdminRole = this.userHasRegionalRole('superadmin');

    if (hasSuperAdminRole) return true;

    return hasRole;
  };

  get isDirectorRole() {
    const hasDirectorRole = this.userHasRegionalRole('director');
    const hasSuperAdminRole = this.userHasRegionalRole('superadmin');

    return hasDirectorRole && !hasSuperAdminRole;
  }

  get userRegionalRoles() {
    return this.regionalRoles?.map((role) => role.key) ?? [];
  }

  get userRolesObj() {
    return this.planningUserAttributes?.user_roles ?? [];
  }

  get userRoles() {
    const roles = this.planningUserAttributes?.user_roles ?? [];
    return roles.map((rd) => rd.key);
  }

  get userProgramTypes() {
    const programTypes = this.planningUserAttributes?.program_types ?? [];
    return programTypes.map((pt) => pt.key);
  }

  get haveAccessToConstruction() {
    if (this.userRoles.includes('superadmin')) return true;

    return this.userProgramTypes.includes('construction_reconstruction');
  }

  get haveAccessToOverhauls() {
    if (this.userRoles.includes('superadmin')) return true;

    return this.userProgramTypes.includes('major_repairs');
  }

  get haveAccessToRepair() {
    if (this.userRoles.includes('superadmin')) return true;

    return this.userProgramTypes.includes('repair');
  }

  get haveAccessToMaintenance() {
    if (this.userRoles.includes('superadmin')) return true;

    return this.userProgramTypes.includes('maintenance');
  }

  haveAccessToProgramType = (programType: ProgramTypeKeys) => {
    switch (programType) {
      case 'overhauls':
        return this.haveAccessToOverhauls;
      case 'maintenance':
        return this.haveAccessToMaintenance;
      case 'construction':
        return this.haveAccessToConstruction;
      case 'repair':
        return this.haveAccessToRepair;
    }
  };

  get userLevels() {
    const levels = this.planningUserAttributes?.levels ?? [];
    return levels.map((lv) => lv.key);
  }

  get haveAccessToFederal() {
    if (this.userRoles.includes('superadmin')) return true;
    // this.userRoles.includes('program_operator') &&
    return this.userLevels.includes('federal');
  }

  get haveAccessToRegional() {
    if (this.userRoles.includes('superadmin')) return true;
    // this.userRoles.includes('program_operator') &&
    return this.userLevels.includes('regional');
  }

  get haveAccessToMunicipal() {
    if (this.userRoles.includes('superadmin')) return true;
    // this.userRoles.includes('program_operator') &&
    return this.userLevels.includes('municipal');
  }

  haveAccessToProgramLevel = (level: Levels) => {
    switch (level) {
      case 'federal':
        return this.haveAccessToFederal;
      case 'regional':
        return this.haveAccessToRegional;
      case 'municipal':
        return this.haveAccessToMunicipal;
    }
  };

  get canCreateProgram() {
    return this.userRoles.includes('superadmin') || this.userRoles.includes('program_operator');
  }

  get canEditProgram() {
    if (this.userRoles.includes('superadmin')) return true;

    return this.userRoles.includes('program_operator');
  }

  get canCreateAggregateProgram() {
    return this.userRoles.includes('superadmin') || this.userRoles.includes('aggregate_program_coordinator');
  }

  get canEditAggregateProgram() {
    return this.userRoles.includes('superadmin');
  }

  get canAddEvent() {
    return (
      this.userRoles.includes('superadmin') ||
      this.userRoles.includes('program_operator') ||
      this.userRoles.includes('events_operator')
    );
  }

  get canAddEventFromRoad() {
    return this.userRoles.includes('superadmin') || this.userRoles.includes('events_operator');
  }

  get canEditEvent() {
    return (
      this.userRoles.includes('superadmin') ||
      this.userRoles.includes('program_operator') ||
      this.userRoles.includes('events_operator') ||
      this.userRoles.includes('extended_program_coordinator')
    );
  }

  get canCreateEventVersion() {
    return (
      this.userRoles.includes('superadmin') ||
      this.userRoles.includes('program_operator') ||
      this.userRoles.includes('events_operator')
    );
  }

  get canMoveEvent() {
    return (
      this.userRoles.includes('superadmin') ||
      this.userRoles.includes('program_operator') ||
      this.userRoles.includes('events_operator')
    );
  }

  get canCreateProgramVersion() {
    return this.userRoles.includes('superadmin') || this.userRoles.includes('program_operator');
  }

  get canEditVolumes() {
    return (
      this.userRoles.includes('superadmin') ||
      this.userRoles.includes('program_operator') ||
      this.userRoles.includes('extended_program_coordinator') ||
      this.userRoles.includes('events_operator')
    );
  }

  get canCreateWork() {
    return (
      this.userRoles.includes('superadmin') ||
      this.userRoles.includes('program_operator') ||
      this.userRoles.includes('events_operator')
    );
  }

  get canEditWork() {
    return (
      this.userRoles.includes('superadmin') ||
      this.userRoles.includes('program_operator') ||
      this.userRoles.includes('events_operator') ||
      this.userRoles.includes('extended_program_coordinator')
    );
  }

  /** Approval **/

  get canSendProgramToApproval() {
    return this.userRoles.includes('superadmin') || this.userRoles.includes('program_operator');
  }

  get canSendProgramToFDAApproval() {
    return (
      this.userRoles.includes('superadmin') ||
      this.userRoles.includes('program_coordinator') ||
      this.userRoles.includes('extended_program_coordinator')
    );
  }

  get canSendAggregateProgramToFDAApproval() {
    return this.userRoles.includes('superadmin') || this.userRoles.includes('aggregate_program_coordinator');
  }

  get canSendProgramToFinalApproval() {
    return (
      this.userRoles.includes('superadmin') || this.userRoles.includes('aggregate_program_coordinator')
      // || this.userRoles.includes('extended_program_coordinator')
    );
  }

  get canFinalApproveProgram() {
    return this.userRoles.includes('signatory');
  }

  get canSendEventToApproval() {
    return (
      this.userRoles.includes('superadmin') ||
      this.userRoles.includes('program_operator') ||
      this.userRoles.includes('aggregate_program_coordinator') ||
      this.userRoles.includes('extended_program_coordinator') ||
      this.userRoles.includes('events_operator')
    );
  }

  get canSendEventToFDAApproval() {
    return (
      this.userRoles.includes('superadmin') ||
      this.userRoles.includes('program_operator') ||
      this.userRoles.includes('program_coordinator') ||
      this.userRoles.includes('aggregate_program_coordinator') ||
      this.userRoles.includes('extended_program_coordinator')
    );
  }

  get canApproveProgram() {
    return (
      this.userRoles.includes('superadmin') ||
      this.userRoles.includes('program_coordinator') ||
      this.userRoles.includes('extended_program_coordinator')
    );
  }

  get canRejectProgram() {
    return (
      this.userRoles.includes('superadmin') ||
      this.userRoles.includes('program_coordinator') ||
      this.userRoles.includes('extended_program_coordinator')
    );
  }

  get canApproveEvent() {
    return (
      this.userRoles.includes('superadmin') ||
      this.userRoles.includes('program_coordinator') ||
      this.userRoles.includes('extended_program_coordinator')
    );
  }

  get canRejectEvent() {
    return (
      this.userRoles.includes('superadmin') ||
      this.userRoles.includes('program_coordinator') ||
      this.userRoles.includes('extended_program_coordinator')
    );
  }

  get canApproveAggregateProgram() {
    return this.userRoles.includes('superadmin') || this.userRoles.includes('aggregate_program_coordinator');
  }

  get canApproveAggregateEvent() {
    return this.userRoles.includes('superadmin') || this.userRoles.includes('aggregate_program_coordinator');
  }

  get canFinalApproveEvent() {
    return this.userRoles.includes('superadmin') || this.userRoles.includes('signatory');
  }
}

export default PlanningAccess;
