import client from '@/httpClient';
import type { AccessLevelResponse, AccessMatrix } from './types';
import { defaultAccessMatrix } from './defaultAccessMatrix';

export async function fetchAccessMatrix() {
  let data: AccessMatrix = defaultAccessMatrix;
  await client.get<AccessMatrix>('common/user/resources-visibility').then((response) => {
    if (response) {
      data = response.data;
      if (data.menu_reporting_form_1_dg) {
        data.menu_reporting_form_1_bkad = true;
        data.menu_reporting_form_1_dg_rf = true;
        data.menu_reporting_form_1_dg_2dg_fku = true;
        data.menu_reporting_form_1_dg_2dg_subject = true;
        data.menu_reporting_form_1_fd_res = true;
        data.menu_reporting_form_1_fd_use = true;
        data.menu_reporting_form_monitoring_plan = true;
        data.menu_reporting_form_monitoring_future = true;
      }
      data.menu_graph_map = true;
    }
  });
  return data;
}

export async function fetchAccessToEndpoint(endpoint: string) {
  const access = await client.get<AccessLevelResponse>(`${endpoint}/access-matrix`);
  return access.data;
}
