import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { FeatureFlags } from '@/features/FeatureFlags';
import { menu1, menu2, TMenuItem, TMenuItemGroup } from '@/menu';

import classes from './Sidebar.module.scss';
import { ReactComponent as IconLogo } from './symbol-skdf.svg';
import { ReactComponent as IconLogoNY } from './new-year-skdf.svg';

import { UserLinks } from './components/UserLinks';
import SidebarLink from './components/SidebarLink';
import { LinkGroup } from './components/LinkGroup';

import { sidebarStoreInstance } from './store';
// import { trainingStore } from '../Training/store';
import { notificationStore } from '@/notifications/store';
import { userStoreInstance } from '../Auth/store';
import { trainingStore } from '@/trainingConfig';

function Sidebar() {
  const { features } = useContext(FeatureFlags);

  // const match = useRouteMatch();
  const location = useLocation();

  const { isActive, isSubActive, activeMenuGroup, subItems, setActive, toggleLinkGroup, close } = sidebarStoreInstance;

  const linkGroupClick = (group: TMenuItemGroup, e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    toggleLinkGroup(group);
    e.preventDefault();
  };

  useEffect(() => {
    if (userStoreInstance.isSignedIn && features.isNotificationsEnabled) {
      notificationStore.getUnreadCount();
    }
    close();
  }, [location, close, features]);

  const isFeatureEnabled = (item: TMenuItem | TMenuItemGroup) => {
    return Object.hasOwn(item, 'featureFlag') && item.featureFlag !== undefined ? features[item.featureFlag] : true;
  };

  const today = new Date();
  const startDate = new Date(today.getFullYear(), 11, 25);
  const endDate = new Date(today.getFullYear(), 0, 8);
  const newYear = today >= startDate || today <= endDate;

  return (
    <>
      <aside
        className={classes.sidebar}
        onMouseEnter={() => setActive(true)}
        onMouseLeave={close}
        style={trainingStore.isOpenTraining ? {} : { zIndex: 20 }}
      >
        <div className={classNames(classes.main, { [classes.active]: isActive })}>
          <a href="/" className={classes.logo}>
            {newYear ? <IconLogoNY style={{ marginTop: '-1.875rem' }} /> : <IconLogo />}
            <span className={classes.logoTitle}>СКДФ</span>
          </a>

          {features.isSKDFDesignEnabled && (
            <nav className={classes.nav}>
              <SidebarLink path="/skdf-design" title="SkdfDesign" icon="plug" />
            </nav>
          )}

          <nav className={classes.nav}>
            {menu1.map((item) => {
              return isFeatureEnabled(item) ? (
                'path' in item ? (
                  <SidebarLink key={item.path} {...item} />
                ) : (
                  <LinkGroup key={item.title} activeMenuGroup={activeMenuGroup} onClick={linkGroupClick} {...item} />
                )
              ) : null;
            })}
          </nav>
          {/*<pre>{JSON.stringify(location, null, 2)}</pre>*/}

          <div className={classes.grow1}></div>

          <nav className={classes.nav}>
            {menu2.map((item) => {
              return isFeatureEnabled(item) ? (
                'path' in item ? (
                  <SidebarLink key={item.path} {...item} />
                ) : (
                  <LinkGroup key={item.title} activeMenuGroup={activeMenuGroup} onClick={linkGroupClick} {...item} />
                )
              ) : null;
            })}
          </nav>
          <hr className={classes.divider} />
          <nav className={classes.nav}>
            <UserLinks />
          </nav>
        </div>
        <div className={classNames(classes.subBar, { [classes.active]: isSubActive })}>
          <nav className={classes.nav}>
            {subItems?.map((item) => (isFeatureEnabled(item) ? <SidebarLink key={item.path} {...item} /> : null))}
          </nav>
        </div>
      </aside>
    </>
  );
}

export default observer(Sidebar);
