export const defaultAccessMatrix = {
  page_account__block_projects_lists_of_highways: false,
  page_account__block_road_activity: false,
  page_account__block_road_activity__roads: false,
  page_account__block_operational_management: false,
  page_account__block_operational_management__protocols: false,
  page_account__block_project_activity: false,
  page_account__block_analitycs: false,
  page_account__block_notifications: false,
  menu_administration: false, // manual
  menu_desktop: false, // manual
  menu_notifications: false, // manual
  menu_account_page: false, //manual
  menu_newsletter: false,
  menu_map: true,
  menu_graph_map: true,
  menu_roads: true,
  menu_bridges: false,
  menu_events: false,
  menu_accidents: true,
  menu_citizen_messages: false,
  menu_documents: false,
  menu_protocols: false,
  menu_projects: false,
  // menu_tasks: false, // removed
  menu_contacts: false,
  menu_road_owners: false,
  menu_open_data: false,
  menu_developers: false,
  menu_contracts: false,
  menu_procurement_plans: false,
  menu_plan_schedules: false,
  menu_purchase_notices: false,
  menu_citizen_appeals: false,
  menu_nsi: false,
  menu_data_import: false,
  menu_registration_applications: false,
  menu_road_diagnostics: false,
  menu_user_accounts: false,
  menu_cubes: false,
  menu_indicators: false,
  menu_analytics: false,
  menu_region_passport: false,
  menu_reporting_form_3_autodor: false,
  menu_reporting_form_7_autodor: false,
  menu_reporting_form_1_dg: false,
  menu_reporting_form_2_dg: false,
  menu_reporting_form_1_fd: false,
  menu_reporting_form_1_km_price: false,
  menu_reporting_form_monitoring: false,
  menu_reporting_form_1_bkad: false, // manual
  menu_reporting_form_1_dg_rf: false, // manual
  menu_reporting_form_1_dg_2dg_subject: false, // manual
  menu_reporting_form_1_dg_2dg_fku: false, // manual
  menu_reporting_form_1_fd_res: false, // manual
  menu_reporting_form_1_fd_use: false, // manual
  menu_reporting_form_monitoring_plan: false, // manual
  menu_reporting_form_monitoring_future: false, // manual
  roads__export: false,
  roads__show_on_map: false,
  roads__reports: false,
  roads_card__export_pdf: false,
  roads_card__edit: false,
  roads_card__print: false,
  roads_card__show_on_map: false,
  roads_card__history: false,
  roads_card__relations: false,
  roads_card__checked_status: false,
  traffic_accidents__export: false,
  traffic_accidents__form_aou: false,
  traffic_accidents__show_on_map: false,
  traffic_accidents__accept_aop: false,
  traffic_accidents__reject_aop: false,
  traffic_accidents_card__export: false,
  traffic_accidents_card__print: false,
  traffic_accidents_card__show_on_map: false,
  traffic_accidents_card__history: false,
  events__export: false,
  events__create: false,
  events__show_on_map: false,
  events_card__print: false,
  events_card__export: false,
  events_card__edit: false,
  events_card__favorite: false,
  events_card__export_pdf: false,
  events_card__show_on_map: false,
  events_card__checked_status: false,
  events_card__history: false,
  bridges__export: false,
  bridges__show_on_map: false,
  bridges_card__print: false,
  bridges_card__edit: false,
  bridges_card__show_on_map: false,
  bridges_card__checked_status: false,
  bridges_card__history: false,
  procurement_plans__export: false,
  procurement_plans_card__print: false,
  procurement_plans_card__export: false,
  procurement_plans_card__edit: false,
  procurement_plans_card__relations: false,
  procurement_plans_card__history: false,
  projects__export: false,
  projects__create: false,
  projects_card__print: false,
  projects_card__edit: false,
  projects_card__export: false,
  projects_card__create_copy: false,
  tasks__export: false,
  tasks__create: false,
  tasks__reports: false,
  tasks__copy: false,
  tasks__delete: false,
  tasks_card__edit: false,
  tasks_card__print: false,
  tasks_card__favorite: false,
  tasks_card__delegate: false,
  tasks_card__get_to_work: false,
  tasks_card__interrupt: false,
  tasks_card__request_for_postponement: false,
  tasks_card__resume: false,
  tasks_card__cancel_postponement: false,
  tasks_card__reply_for_postponement: false,
  tasks_card__complete: false,
  tasks_card__accept: false,
  tasks_card__reject: false,
  documents__export: false,
  documents__create: false,
  documents_card__edit: false,
  documents_card__print: false,
  documents_card__export: false,
  documents_card__favorite: false,
  documents_card__acquaint: false,
  'documents_card__start-approve': false,
  'documents_card__restart-approve': false,
  'documents_card__reset-approve': false,
  'documents_card__interrupt-approve': false,
  'documents_card__reject-approve': false,
  'documents_card__accept-approve': false,
  'documents_card__create-approve-sub-step': false,
  plan_schedules__export: false,
  plan_schedules_card__export: false,
  plan_schedules_card__print: false,
  plan_schedules_card__edit: false,
  plan_schedules_card__relations: false,
  plan_schedules_card__history: false,
  purch_notices__export: false,
  purch_notices_card__export: false,
  purch_notices_card__print: false,
  purch_notices_card__edit: false,
  purch_notices_card__relations: false,
  purch_notices_card__history: false,
  contracts__export: false,
  contracts__create: false,
  contracts_card__export: false,
  contracts_card__print: false,
  contracts_card__relations: false,
  contracts_card__edit: false,
  contracts_card__checked_status: false,
  contracts_card__history: false,
  'nsi__section-data': false,
  'nsi__section-setup': false,
  nsi__export: false,
  nsi__import: false,
  nsi__edit: false,
  'data_import__section-data': false,
  'data_import__section-configurator': false,
  protocols__export: false,
  protocols__create: false,
  protocols__reports: false,
  protocols_card__print: false,
  protocols_card__edit: false,
  protocols_card__common_info__edit: false,
  protocols_card__files__edit: false,
  protocols_card__participants__edit: false,
  protocols_card__resolutions__edit: false,
  protocols_card__export: false,
  messages__export: false,
  messages__deadline_settings: false,
  messages_card__print: false,
  messages_card__show_on_map: false,
  messages_card__export: false,
  messages_card__status_history: false,
  messages_card__change_status: false,
  messages_card__history: false,
  users__export: false,
  users__create: false,
  users__groups: false,
  user_groups__add_group: false,
  user_groups__form_by_rules: false,
  user_groups__edit: false,
  user_groups__favorite: false,
  users_card__print: false,
  users_card__edit: false,
  user_groups_card__print: false,
  map__show_as_table: false,
  map__print: false,
  map__add: false,
  map__relations: false,
  map__edit: false,
  map__to_card: false,
  road_on_balance_card__export_pdf: false,
  road_on_balance_card__edit: false,
  road_on_balance_card__print: false,
  road_on_balance_card__show_on_map: false,
  road_on_balance_card__checked_status: false,
  road_on_balance_card__history: false,
  user_accounts__export: false,
  user_accounts_card__edit: false,
  user_accounts_card__print: false,
  user_accounts_card__history: false,
  user_accounts_card__actions__block: false,
  user_accounts_card__actions__refresh_password: false,
  user_accounts_card__actions__register: false,
  user_accounts_card__actions__set_as_clarified: false,
  user_accounts_card__actions__set_as_duplicate: false,
  user_accounts_card__actions__set_as_on_clarification: false,
  user_accounts_card__actions__unblock: false,
  user_accounts_card__actions__refresh_original_password: false,
  reporting_form_3_autodor__organization_report_list: false,
  reporting_form_3_autodor__subject_rf_report_list: false,
  reporting_form_3_autodor__summary: false,
  reporting_form_3_autodor__confirm_doc_edit: false,
  reporting_form_3_autodor__edit: false,
  reporting_form_3_autodor__import: false,
  reporting_form_3_autodor__approvement__send: false,
  reporting_form_3_autodor__approvement__start_new: false,
  reporting_form_3_autodor__approvement__approve: false,
  reporting_form_3_autodor__approvement__reject: false,
  reporting_form_3_autodor__auto_calc: false,
  reporting_form_7_autodor__summary: false,
  reporting_form_7_autodor__confirm_doc_edit: false,
  reporting_form_7_autodor__edit: false,
  reporting_form_7_autodor__import: false,
  reporting_form_7_autodor__approvement__send: false,
  reporting_form_7_autodor__approvement__start_new: false,
  reporting_form_7_autodor__approvement__approve: false,
  reporting_form_7_autodor__approvement__reject: false,
  reporting_form_7_autodor__auto_calc: false,
  reporting_form_1_dg__organization_report_list: false,
  reporting_form_1_dg__subject_rf_report_list: false,
  reporting_form_1_dg__summary: false,
  reporting_form_1_dg__confirm_doc_edit: false,
  reporting_form_1_dg__edit: false,
  reporting_form_1_dg__import: false,
  reporting_form_1_dg__approvement__send: false,
  reporting_form_1_dg__approvement__start_new: false,
  reporting_form_1_dg__approvement__approve: false,
  reporting_form_1_dg__approvement__reject: false,
  reporting_form_1_dg__auto_calc: false,
  reporting_form_2_dg__organization_report_list: false,
  reporting_form_2_dg__subject_rf_report_list: false,
  reporting_form_2_dg__summary: false,
  reporting_form_2_dg__confirm_doc_edit: false,
  reporting_form_2_dg__edit: false,
  reporting_form_2_dg__import: false,
  reporting_form_2_dg__approvement__send: false,
  reporting_form_2_dg__approvement__start_new: false,
  reporting_form_2_dg__approvement__approve: false,
  reporting_form_2_dg__approvement__reject: false,
  reporting_form_2_dg__auto_calc: false,
  reporting_form_1_fd__organization_report_list: false,
  reporting_form_1_fd__subject_rf_report_list: false,
  reporting_form_1_fd__summary: false,
  reporting_form_1_fd__confirm_doc_edit: false,
  reporting_form_1_fd__edit: false,
  reporting_form_1_fd__import: false,
  reporting_form_1_fd__approvement__send: false,
  reporting_form_1_fd__approvement__start_new: false,
  reporting_form_1_fd__approvement__approve: false,
  reporting_form_1_fd__approvement__reject: false,
  reporting_form_1_fd__auto_calc: false,
  reporting_form_1_fd__laws_edit: false,
  reporting_form_monitoring__summary: false,
  reporting_form_monitoring__confirm_doc_edit: false,
  reporting_form_monitoring__edit: false,
  reporting_form_monitoring__import: false,
  reporting_form_monitoring__approvement__send: false,
  reporting_form_monitoring__approvement__start_new: false,
  reporting_form_monitoring__approvement__approve: false,
  reporting_form_monitoring__approvement__reject: false,
  reporting_form_monitoring__auto_calc: false,
  reporting_form_monitoring__laws_edit: false,
  reporting_form_1_km_price__organization_report_list: false,
  reporting_form_1_km_price__subject_rf_report_list: false,
  reporting_form_1_km_price__summary: false,
  reporting_form_1_km_price__confirm_doc_edit: false,
  reporting_form_1_km_price__edit: false,
  reporting_form_1_km_price__import: false,
  reporting_form_1_km_price__approvement__send: false,
  reporting_form_1_km_price__approvement__start_new: false,
  reporting_form_1_km_price__approvement__approve: false,
  reporting_form_1_km_price__approvement__reject: false,
  reporting_form_1_km_price__auto_calc: false,
  page_account__block_statistics: false,
  page_account__block_statistics__form_3_autodor: false,
  page_account__block_statistics__form_3_autodor__part_organizations: false,
  page_account__block_statistics__form_3_autodor__part_regions: false,
  page_account__block_statistics__form_7_autodor: false,
  page_account__block_statistics__form_7_autodor__part_organizations: false,
  page_account__block_statistics__form_1_dg: false,
  page_account__block_statistics__form_1_dg__part_organizations: false,
  page_account__block_statistics__form_1_dg__part_regions: false,
  page_account__block_statistics__form_2_dg: false,
  page_account__block_statistics__form_2_dg__part_organizations: false,
  page_account__block_statistics__form_2_dg__part_regions: false,
  page_account__block_statistics__form_1_fd: false,
  page_account__block_statistics__form_1_fd__part_organizations: false,
  page_account__block_statistics__form_1_fd__part_regions: false,
  page_account__block_statistics__form_1_km_price: false,
  page_account__block_statistics__form_1_km_price__part_organizations: false,
  page_account__block_statistics__form_1_km_price__part_regions: false,
  page_account__block_statistics__form_monitoring: false,
  page_account__block_statistics__form_monitoring__part_regions: false,
  menu_events_federal: false,
  menu_events_regional: false,
};
