import { defaultAccessMatrix } from './defaultAccessMatrix';

export type AccessMatrixKey = keyof typeof defaultAccessMatrix;

export type AccessMatrix = Record<AccessMatrixKey, boolean>;

export enum AccessLevel {
  Forbidden = 0,
  Read = 1,
  Write = 2,
}

export type AccessLevelResponse = {
  accessLevel: AccessLevel;
  accessMatrix?: Partial<AccessMatrix>;
};
