import { ReactNode, createContext } from 'react';
import { observer } from 'mobx-react-lite';
import featureFlagsStoreInstance from './store';

type FeatureFlagsContext = { features: Record<string, boolean> };

export const FeatureFlags = createContext<FeatureFlagsContext>({ features: {} });

const FeatureFlagsProvider = ({ children }: { children: ReactNode }) => {
  const { features } = featureFlagsStoreInstance;

  return <FeatureFlags.Provider value={{ features }}>{children}</FeatureFlags.Provider>;
};

export default observer(FeatureFlagsProvider);
