import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import SkdfIcon from '@/components/SkdfIcon';
import Button from '@/components/Button';
import { Avatar } from '@skdf/ui';

import { userStoreInstance } from '@/features/Auth/store';
import classes from '../Sidebar.module.scss';
import { observer } from 'mobx-react-lite';

const UserLinksComponent = () => {
  const { user } = userStoreInstance;

  if (user === null || user === 'anonymous') {
    return (
      <div key="signInLink" className={classes.signInLink}>
        <Button icon="login" variant="function" className={classes.navLink} onClick={() => userStoreInstance.login()}>
          <span className={classes.navLinkTitle}>Вход</span>
        </Button>
      </div>
    );
  }

  const { profile } = user;

  let display_name = '';

  if (profile.full_name as string) {
    display_name = profile.full_name as string;
  } else if (profile.display_name && (profile.display_name as string[]).length) {
    display_name = (profile.display_name as string[])[0];
  } else if (profile.family_name && profile.name && profile.middle_name) {
    display_name = `${profile.family_name} ${profile.name} ${profile.middle_name}`;
  } else if (profile.family_name && profile.name) {
    display_name = `${profile.name} ${profile.family_name}`;
  } else {
    display_name = profile.sub;
  }

  if (profile.idp === 'Esia') {
    return (
      <div key="userLinks" className={classes.userLinks}>
        <div className={classNames(classes.navLink, classes.grow1)}>
          <Avatar size={36} name={display_name} />
          <span className={classes.navLinkTitle}>{display_name}</span>
        </div>
        <Button variant="function" onClick={() => userStoreInstance.logout()} className={classes.navLink}>
          <SkdfIcon name="leave" />
        </Button>
      </div>
    );
  }

  return (
    <div key="userLinks" className={classes.userLinks}>
      <NavLink
        to="/account"
        className={({ isActive }) => classNames(classes.navLink, classes.grow1, { [classes.active]: isActive })}
      >
        <Avatar size={36} name={display_name} />
        <span className={classes.navLinkTitle}>{display_name}</span>
      </NavLink>
      <Button variant="function" onClick={() => userStoreInstance.logout()} className={classes.navLink}>
        <SkdfIcon name="leave" />
      </Button>
    </div>
  );
};

const UserLinks = observer(UserLinksComponent);

export { UserLinks };
