import { NavLink } from 'react-router-dom';
// import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import classes from '../Sidebar.module.scss';
import { Badge, SkdfIcon } from '@skdf/ui';
import type { TMenuItem } from '@/menu';

import { userStoreInstance } from '@/features/Auth/store';
import { notificationStore } from '@/notifications/store';

const { doesHaveAccess } = userStoreInstance.accessMatrix;

const generateLink = (link: string) => {
  if (userStoreInstance.planningAccess) {
    if (userStoreInstance.planningAccess.haveAccessToFederal) return link + 'federal';
    if (userStoreInstance.planningAccess.haveAccessToRegional) return link + 'regional';
    if (userStoreInstance.planningAccess.haveAccessToMunicipal) return link + 'municipal';
  }
  return link + 'federal';
};

function SidebarLink({ path, icon, title, accessKey }: TMenuItem) {
  const haveAccess = accessKey ? doesHaveAccess(accessKey) : true;

  const newPath = accessKey === 'menu_events' && path.includes('planningOld') ? generateLink(path) : path;

  return (
    <>
      {haveAccess ? (
        <NavLink to={newPath} className={({ isActive }) => classNames(classes.navLink, { [classes.active]: isActive })}>
          {path === '/notifications' ? (
            <span className="d-inline-block position-relative">
              {icon && <SkdfIcon name={icon} />}
              {notificationStore.countUnread > 0 && <Badge counter>{notificationStore.countUnread}</Badge>}
            </span>
          ) : (
            icon && <SkdfIcon name={icon} />
          )}

          <span className={classes.navLinkTitle}>{title}</span>
        </NavLink>
      ) : null}
    </>
  );
}

export default SidebarLink;
