import client from './httpClient';

export type TFile = UploadFileResponse & {
  id: number;
  size_desc: string;
  create_time: string;
  user: { id: number; name: string; role: string };
};

export type UploadFileResponse = {
  mime_type: string;
  name: string;
  path: string;
  size: number;
};

export async function getFileDownload(id: string) {
  const response = await client.get<Blob>(`/files/download/${id}`, {
    responseType: 'blob',
  });
  return response.data;
}

export async function uploadFile(file: File) {
  const fd = new FormData();
  fd.append('file', file);
  const response = await client.post<string[], { data: UploadFileResponse }>(`/files/upload`, fd);
  return response.data;
}

export type AuthorityLevelItem = {
  id: number;
  key: string;
  name: string;
};

export async function fetchAuthorityLevels() {
  const response = await client.get<AuthorityLevelItem[]>('/planning/dictionaries/authority_levels');
  return response.data;
}

export async function fetchPhotoFixationResults() {
  const response = await client.get('/planning/dictionaries/photo_fixation_results');
  return response.data;
}

export type UserInfo = {
  org_id: number;
  org_short_name: string;
  user_id: number;
  user_region: string;
  user_region_id: number;
};

export type OrganizationAndRegion = {
  data: UserInfo[];
};

export async function fetchUserOrganizationAndRegion() {
  const response = await client.get<OrganizationAndRegion>('/planning/user/org_region');
  return response.data.data;
}

export type DictItem = {
  id: number;
  name: string;
  key: string;
};

export interface PlanningUser {
  user_id: number;
  user_roles: RolesDict[];
  levels: LevelsDict[];
  program_types: ProgramTypesDict[];
  work_types: DictItem[];
  obj_types: DictItem[];
  is_aggregate: boolean;
  role_desc: string;
}

export interface PlanningUserAttributesResponse {
  data: PlanningUser;
}

export type UserRoles =
  | 'program_operator'
  | 'events_operator'
  | 'program_coordinator'
  | 'aggregate_program_coordinator'
  | 'extended_program_coordinator'
  | 'signatory'
  | 'superadmin'
  | 'director';

export interface RolesDict extends DictItem {
  key: UserRoles;
}

export type ProgramTypes = 'construction_reconstruction' | 'major_repairs' | 'repair' | 'maintenance';

export interface ProgramTypesDict extends DictItem {
  key: ProgramTypes;
}

export type Levels = 'federal' | 'regional' | 'municipal';

export interface LevelsDict extends DictItem {
  key: Levels;
}

export async function fetchPlanningUserAttributes() {
  const response = await client.get<PlanningUserAttributesResponse>('/planning/user/attributes');
  return response.data.data;
}
