import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import FeatureFlagsProvider from '@/features/FeatureFlags';
import '@skdf/ui/dist/scss/bs_skdf.scss';

const container = document.getElementById('root');

if (container === null) {
  throw new Error('Container for react app is missing!');
}

const root = createRoot(container);

root.render(
  <FeatureFlagsProvider>
    <App />
  </FeatureFlagsProvider>
);
