import client from '@/httpClient';

export type Account = {
  workplaces: Workplaces;
  visibleBlocks: VisibleBlock[];
  id: number;
  hash: string;
  isAuthor: boolean;
  fields: FieldData[];
};

export type FieldData = {
  value?: Field;
  isCustom: boolean;
  id: number;
  code: string;
  type: number;
  title?: string;
  isMultiple: boolean;
  isRequired: boolean;
  isReadonly: boolean;
  isEntity: boolean;
  isPaged: boolean;
  name?: string;
};

export type Field = {
  isChanged: boolean;
  value: FieldValue[] | boolean | string;
};

export type FieldValue = {
  id?: number | string;
  code?: string;
  text?: string;
  isNew: boolean;
  isDeleted: boolean;
  value?: string;
  storageFileId?: string;
  hash?: string;
  name?: string;
};

export type VisibleBlock = {
  id: number;
  itemCount: number;
};

export type Workplaces = {
  metadata: Field[];
  data: WorkplaceData[];
};

export type WorkplaceData = {
  organization: Ion;
  subdivision: Ion;
  position: Ion;
  id: number;
  isNew: boolean;
  isDeleted: boolean;
};

export type Ion = {
  isChanged: boolean;
  value: OrganizationValue[];
};

export type OrganizationValue = {
  id: number;
  relationId?: number;
  text: string;
  isNew: boolean;
  isDeleted: boolean;
};

export async function fetchProfile(): Promise<Account> {
  const response = await client.get<Account>('account');
  return response.data;
}
