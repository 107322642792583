import axios from 'axios';
import { toast } from 'react-toastify';

import { userStoreInstance } from '@/features/Auth/store';
import { RequestManager } from '@/httpClient';

const v3 = import.meta.env.VITE_API_V3_URL || 'http://localhost:3000/api/v3';

export const baseURL = `${v3}/skdf`;

const client = axios.create({ baseURL });

// Request interceptor for API calls
client.interceptors.request.use(
  async (config) => {
    const { user } = userStoreInstance;
    if (user && user !== 'anonymous') {
      config.headers = {
        Authorization: `Bearer ${user.access_token}`,
      };
      config.baseURL = baseURL;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
client.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalRequest = error.config;
    if (error.response?.status === 404) {
      return error.response;
    }
    if ([403, 401].includes(error.response?.status)) {
      if (originalRequest._retry) {
        await userStoreInstance.removeUser();
        window.location.reload();
      } else {
        originalRequest._retry = true;
        await userStoreInstance.renewToken().catch(async () => {
          await userStoreInstance.removeUser();
          window.location.reload();
        });
        const { user } = userStoreInstance;
        if (user && user !== 'anonymous') {
          axios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`;
          return client(originalRequest);
        }
      }
    } else if (error.code === 'ERR_CANCELED') {
      return;
    }

    return Promise.reject(error);
  }
);

if (import.meta.env.DEV) {
  client.interceptors.response.use(
    (response) => response,
    (error) => {
      let message = String(error);
      if (axios.isAxiosError(error)) message = error.message;
      if (error.response?.status === 404) {
        return error.response;
      }
      const errorObj = error?.response?.data?.error;

      if (typeof errorObj === 'string') {
        if (errorObj === 'user error: Была попытка создать дубль существующей программы') {
          toast.error('Создание дублей существующих программ запрещено');
          return;
        }
      }

      const customError = errorObj?.message;

      toast.error(customError || message, { autoClose: false });
    }
  );
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window['httpPlanningClient'] = client;
} else if (import.meta.env.PROD) {
  client.interceptors.response.use(undefined, (error) => {
    const customError = error?.response?.data?.error?.message;

    toast.error(customError || 'Произошла ошибка при загрузке данных', { autoClose: false });
  });
}

export const planningDefaultRequestManager = new RequestManager(client);

export default client;
