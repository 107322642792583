import { makeAutoObservable } from 'mobx';
import { ModalProps } from 'react-bootstrap';

export type MultiStepItemBtn = {
  label?: string;
  action?: () => void;
  isHidden?: boolean;
  isDisabled?: boolean;
};

export type MultiStepItem = {
  title?: string;
  breadСrumb?: string;
  component: JSX.Element;
  prevBtn?: MultiStepItemBtn;
  nextBtn?: MultiStepItemBtn;
};

export type MultiStepOpenProps = {
  title?: string;
  modalProps?: ModalProps;
  steps: MultiStepItem[];
  hideCloseBtn?: boolean;
};

export class MultiStepModalStore {
  isLoading = false;
  isOpen = false;
  title: string | undefined = undefined;
  currentStep = 0;
  hideCloseBtn = false;

  modalProps: ModalProps | undefined = undefined;

  steps: MultiStepItem[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  open = ({ title, modalProps, steps, hideCloseBtn }: MultiStepOpenProps) => {
    this.title = title;
    this.steps = steps;

    this.modalProps = modalProps;

    this.hideCloseBtn = !!hideCloseBtn;

    this.isOpen = true;
  };

  close = () => {
    this.isOpen = false;

    this.title = '';
    this.currentStep = 0;
    this.steps = [];
  };

  prevStep = () => {
    --this.currentStep;
  };

  nextStep = () => {
    ++this.currentStep;
  };

  prevStepHandler = () => {
    if (this.step?.prevBtn?.action === undefined) {
      this.prevStep();
    } else {
      this.step.prevBtn.action();
    }
  };

  nextStepHandler = () => {
    if (this.step?.nextBtn?.action === undefined) {
      this.nextStep();
    } else {
      this.step.nextBtn.action();
    }
  };

  goToStep = (step: number) => {
    this.currentStep = step;
  };

  disableActionBtn = (value: boolean) => {
    if (!this.steps.length) return;

    (this.steps[this.currentStep].nextBtn as MultiStepItemBtn).isDisabled = value;
  };

  updateStep = (value: unknown, step: number, path: string[]) => {
    if (path.length === 2) {
      // TODO
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.steps[step][path[0]][path[1]] = value;
    } else {
      // TODO
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.steps[step][path[0]] = value;
    }
  };

  get step() {
    if (this.steps?.length) return this.steps[this.currentStep];
  }

  get hasBreadСrumbs() {
    return this.steps.some((step) => step.breadСrumb);
  }
}

const multiStepModalStore = new MultiStepModalStore();
export default multiStepModalStore;
