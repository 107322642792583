import client from '@/notificationsClient';
import type {
  CategoriesType,
  GetSettingsType,
  NotificationsCountType,
  NotificationsType,
  NotificationSettings,
} from './types';

export async function fetchNotifications({ limit = 0, offset = 0, category_id = 0 }) {
  const response = await client.get<NotificationsType>(`notifications`, {
    params: { limit, offset, category_id },
  });
  return response.data;
}

export async function fetchNotificationsUnread({ limit = 0, offset = 0 }) {
  const response = await client.get<NotificationsType>(`notifications/unread`, {
    params: { limit, offset },
  });
  return response.data;
}

export async function fetchCategories() {
  const response = await client.get<CategoriesType>(`notifications/categories`);
  return response.data;
}

export async function fetchUnreadCount() {
  const response = await client.get<NotificationsCountType>(`notifications/unread/count`);
  return response.data;
}

export async function postUnread(id: string) {
  const response = await client.post<NotificationsCountType>(`notifications/${id}/unread`);
  return response.data;
}

export async function postRead(id: string) {
  const response = await client.post<NotificationsCountType>(`notifications/${id}/read`);
  return response.data;
}

export async function postReadAll() {
  const response = await client.post<NotificationsType>(`notifications/read`);
  return response.data;
}

export async function fetchSettings() {
  const response = await client.get<GetSettingsType>(`notifications/settings`);
  return response.data;
}

export async function postSettings(data: NotificationSettings[]) {
  const response = await client.post(`notifications/settings`, { settings: data });
  return response.data;
}

export type PostNotificationRequest = {
  category_id: number;
  channel_id: number;
  message: string;
  name: string;
  // template_name?: string; TODO: разобраться с шаблоном
  user_ids: number[];
};

export async function postNotifications(payload: PostNotificationRequest) {
  await client.post<NotificationsCountType>(`notifications`, payload);
}
